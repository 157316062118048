.faq {
  margin-bottom: 1rem;
}

.question {
  cursor: pointer;
}

.answer {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.answer.closed {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
}

.answer.open {
  height: auto; 
  opacity: 1;
  visibility: visible;
}