@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;300;400;500;600;700;800;900&display=swap');

*{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Jost', sans-serif;
}

@layer components {
    .green-container{
        display: grid;
        background-color: #1e1f25;
        grid-template-columns: 1fr min(1110px, 94%) 1fr;
        grid-template-rows: 112px auto 697px;
    }

    .green-container > * {
        grid-column: 2;
    }

    .green-container > .fluid{
        grid-column: 1 / -1;
    }

    .partner-shadow{
        box-shadow: 0px 24px 12px 0 rgba(47, 65, 129, 0.1);
    }

    .partners{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 24px;
        margin-top: 24px;
    }

}

.card {
  perspective: 1000px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card.flipped {
  transform: rotateY(180deg);
}

.card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-front {
  background-color: #f7c816; /* Tailwind CSS gray-200 */
}

.card-back {
  background-color: #EF4444; /* Tailwind CSS red-500 */
  transform: rotateY(180deg);
}

.box-shadow{
    -webkit-box-shadow: 0px 0px 9px 3px rgba(26,190,111,0.27);
    -moz-box-shadow: 0px 0px 9px 3px rgba(26,190,111,0.27);
    box-shadow: 0px 0px 9px 3px rgba(26,190,111,0.27);
}

.popup-shadow{
    -webkit-box-shadow: 0px 0px 9px 3px rgba(26,190,111,0.27);
    -moz-box-shadow: 0px 0px 9px 3px rgba(26,190,111,0.27);
    box-shadow: 0px 0px 9px 3px rgba(26,190,111,0.27);
}
