.flip-card {
  background-color: transparent;
  width: 100%;
  height: 180px;
  perspective: 1000px;
  border-radius: 10px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 10px;

}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   border:1px solid #1ABE6F;
}

.flip-card-front {
  background-color: #5A5D6D;
  color: black;
}

.flip-card-back {
  background-color: #5A5D6D;
  color: white;
  transform: rotateY(180deg);
}

.box-shadow{
    -webkit-box-shadow: 0px 0px 9px 3px rgba(26,190,111,0.27);
    -moz-box-shadow: 0px 0px 9px 3px rgba(26,190,111,0.27);
    box-shadow: 0px 0px 9px 3px rgba(26,190,111,0.27);
}
